import React from "react";
import ReviewsPage from "../pages/ReviewsPage";
import { CreateActionProvider } from "../actions/create/CreateActionContext";
import { RemoveActionProvider } from "../actions/remove/RemoveActionContext";
import { ListActionProvider } from "../actions/list/ListActionContext";
import { EditActionProvider } from "../actions/edit/EditActionContext";

export default function ReviewRouterWrapper(props: {
  config?: any;
  filters?: any;
  key?: string;
  formDefaultValues?: any;
  formFilterValues?: any;
}) {
  const {
    config,
    filters,
    key,
    formDefaultValues,
    formFilterValues,
  } = props;

  return (
    <ListActionProvider key={key}>
      <RemoveActionProvider>
        <CreateActionProvider>
          <EditActionProvider>
            <ReviewsPage
              config={config}
              filters={filters}
              formDefaultValues={formDefaultValues}
              formFilterValues={formFilterValues}
            />
          </EditActionProvider>
        </CreateActionProvider>
      </RemoveActionProvider>
    </ListActionProvider>
  );
}
