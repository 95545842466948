import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import Panel from "@main/panel-components/Panel";
import Login from "@beeldit/user-and-access/auth/pages/Login";
import { AuthProvider } from "@beeldit/user-and-access/auth/AuthContext";
import React from "react";
import TenantsPage from "@main/tenants/pages/TenantsPage";
import EventsPage from "@main/events/pages/EventsPage";
import { ListActionProvider as EventListActionProvider } from "./events/actions/list/ListActionContext";
import { CreateActionProvider as EventCreateActionProvider } from "./events/actions/create/CreateActionContext";
import { RemoveActionProvider as EventRemoveActionProvider } from "./events/actions/remove/RemoveActionContext";
import { EditActionProvider as EventEditActionProvider } from "./events/actions/edit/EditActionContext";
import { DetailsActionProvider as EventDetailsActionProvider } from "./events/actions/details/DetailsActionContext";
import EventRulePricePage from "@main/modules/event-rule-prices/pages/EventRulePricePage";
import { EventRulePriceProvider } from "@main/modules/event-rule-prices/contexts/EventRulePriceContext";
import { ListActionProvider as TenantListActionProvider } from "./tenants/actions/list/ListActionContext";
import { CreateActionProvider as TenantCreateActionProvider } from "./tenants/actions/create/CreateActionContext";
import { RemoveActionProvider as TenantRemoveActionProvider } from "./tenants/actions/remove/RemoveActionContext";
import { EditActionProvider as TenantEditActionProvider } from "./tenants/actions/edit/EditActionContext";
import { ListActionProvider as CommerceListActionProvider } from "./commerces/actions/list/ListActionContext";
import { CreateActionProvider as CommerceCreateActionProvider } from "./commerces/actions/create/CreateActionContext";
import { RemoveActionProvider as CommerceRemoveActionProvider } from "./commerces/actions/remove/RemoveActionContext";
import { EditActionProvider as CommerceEditActionProvider } from "./commerces/actions/edit/EditActionContext";
import { DetailsActionProvider as CommerceDetailsActionProvider } from "./commerces/actions/details/DetailsActionContext";
import CommercePage from "./commerces/pages/CommerceDashboardPage";
import CommercesPage from "./commerces/pages/CommercesPage";
import { TenantAndCommerceProvider } from "./contexts/TenantAndCommerceContext";
import EventDashboardPage from "./events/pages/EventDashboardPage";
import { GenerateEventSessionsProvider } from "./events/actions/generate-event-sessions/GenerateEventSessionsContext";

import HeaderDashboardPage from "@bloonde/cms/cms-headers/pages/HeaderDashboardPage";
import { DetailsActionProvider as HeaderDetailsActionProvider } from "@bloonde/cms/cms-headers/actions/details/DetailsActionContext";
import FooterDashboardPage from "@bloonde/cms/cms-footers/pages/FooterDashboardPage";
import { DetailsActionProvider as FooterDetailsActionProvider } from "@bloonde/cms/cms-footers/actions/details/DetailsActionContext";
import { ListActionProvider as PageListActionProvider } from "@bloonde/cms/cms-pages/actions/list/ListActionContext";
import { CreateActionProvider as PageCreateActionProvider } from "@bloonde/cms/cms-pages/actions/create/CreateActionContext";
import { RemoveActionProvider as PageRemoveActionProvider } from "@bloonde/cms/cms-pages/actions/remove/RemoveActionContext";
import PagesPage from "@bloonde/cms/cms-pages/pages/PagesPage";

import QRScannerDashboardPage from "@main/qr-scanner/pages/QRScannerDashboardPage";
import { DetailsActionProvider as QRScannerDetailsActionProvider } from "@main/qr-scanner/actions/details/DetailsActionContext";

import { DetailsActionProvider as PageDetailsActionProvider } from "@bloonde/cms/cms-pages/actions/details/DetailsActionContext";
import PageEditionPage from "@bloonde/cms/cms-pages/pages/PageEditionPage";
import { ConfigurationsProvider } from "./contexts/ConfigurationsContext";

import { ListActionProvider as UsersActivityListActionProvider } from "@beeldit/user-and-access/activity/actions/list/ListActionContext";
import { DetailsActionProvider as UsersActivityDetailsActionProvider } from "@beeldit/user-and-access/activity/actions/details/DetailsActionContext";
import UsersActivityPage from "@beeldit/user-and-access/activity/pages/UsersActivityPage";

import { EditActionProvider as UserEditActionProvider } from "@beeldit/user-and-access/users/actions/edit/EditActionContext";
import { ListActionProvider as UserListActionProvider } from "@beeldit/user-and-access/users/actions/list/ListActionContext";
import { CreateActionProvider as UserCreateActionProvider } from "@beeldit/user-and-access/users/actions/create/CreateActionContext";
import { RemoveActionProvider as UserRemoveActionProvider } from "@beeldit/user-and-access/users/actions/remove/RemoveActionContext";
import UserPage from "@beeldit/user-and-access/users/pages/UserPage";

import { EditActionProvider as CouponEditActionProvider } from "@beeldit/coupons/coupons/actions/edit/EditActionContext";
import { ListActionProvider as CouponListActionProvider } from "@beeldit/coupons/coupons/actions/list/ListActionContext";
import { CreateActionProvider as CouponCreateActionProvider } from "@beeldit/coupons/coupons/actions/create/CreateActionContext";
import { RemoveActionProvider as CouponRemoveActionProvider } from "@beeldit/coupons/coupons/actions/remove/RemoveActionContext";
import CouponsPage from "@beeldit/coupons/coupons/pages/CouponPage";

import { EditActionProvider as AssignableTypeEditActionProvider } from "@beeldit/resource-management/assignable-types/actions/edit/EditActionContext";
import { ListActionProvider as AssignableTypeListActionProvider } from "@beeldit/resource-management/assignable-types/actions/list/ListActionContext";
import { CreateActionProvider as AssignableTypeCreateActionProvider } from "@beeldit/resource-management/assignable-types/actions/create/CreateActionContext";
import { RemoveActionProvider as AssignableTypeRemoveActionProvider } from "@beeldit/resource-management/assignable-types/actions/remove/RemoveActionContext";
import AssignableTypesPage from "@beeldit/resource-management/assignable-types/pages/AssignableTypesPage";

import { EditActionProvider as FeatureEditActionProvider } from "@beeldit/resource-management/features/actions/edit/EditActionContext";
import { ListActionProvider as FeatureListActionProvider } from "@beeldit/resource-management/features/actions/list/ListActionContext";
import { CreateActionProvider as FeatureCreateActionProvider } from "@beeldit/resource-management/features/actions/create/CreateActionContext";
import { RemoveActionProvider as FeatureRemoveActionProvider } from "@beeldit/resource-management/features/actions/remove/RemoveActionContext";
import FeaturesPage from "@beeldit/resource-management/features/pages/FeaturesPage";

import { EditActionProvider as AssignableEditActionProvider } from "@beeldit/resource-management/assignables/actions/edit/EditActionContext";
import { ListActionProvider as AssignableListActionProvider } from "@beeldit/resource-management/assignables/actions/list/ListActionContext";
import { CreateActionProvider as AssignableCreateActionProvider } from "@beeldit/resource-management/assignables/actions/create/CreateActionContext";
import { RemoveActionProvider as AssignableRemoveActionProvider } from "@beeldit/resource-management/assignables/actions/remove/RemoveActionContext";
import AssignablesPage from "@beeldit/resource-management/assignables/pages/AssignablesPage";

import { EditActionProvider as CandidateEditActionProvider } from "@beeldit/resource-management/candidates/actions/edit/EditActionContext";
import { ListActionProvider as CandidateListActionProvider } from "@beeldit/resource-management/candidates/actions/list/ListActionContext";
import { CreateActionProvider as CandidateCreateActionProvider } from "@beeldit/resource-management/candidates/actions/create/CreateActionContext";
import { RemoveActionProvider as CandidateRemoveActionProvider } from "@beeldit/resource-management/candidates/actions/remove/RemoveActionContext";
import CandidatesPage from "@beeldit/resource-management/candidates/pages/CandidatesPage";

import { EditActionProvider as AssignmentEditActionProvider } from "@beeldit/resource-management/assignments/actions/edit/EditActionContext";
import { ListActionProvider as AssignmentListActionProvider } from "@beeldit/resource-management/assignments/actions/list/ListActionContext";
import { CreateActionProvider as AssignmentCreateActionProvider } from "@beeldit/resource-management/assignments/actions/create/CreateActionContext";
import { RemoveActionProvider as AssignmentRemoveActionProvider } from "@beeldit/resource-management/assignments/actions/remove/RemoveActionContext";
import { GenerateAssignmentsProvider as AssignmentGenerateAssignmentsProvider } from "@beeldit/resource-management/assignments/actions/generate-assignments/GenerateAssignmentsContext";
import { DetailsActionProvider as AssignmentDetailsActionProvider } from "@beeldit/resource-management/assignments/actions/details/DetailsActionContext";
import AssignmentsPage from "@beeldit/resource-management/assignments/pages/AssignmentsPage";
import AssignmentPage from "@beeldit/resource-management/assignments/pages/AssignmentPage";
import { EditAssignationActionProvider } from "@beeldit/resource-management/assignments/actions/edit-assignation/EditAssignationActionContext";
import PageRouterWrapper from "@bloonde/cms/cms-pages/wrappers/PageRouterWrapper";

import InvoiceRouterWrapper from "@bloonde/billing/invoices/wrappers/InvoiceRouterWrapper";
import InvoiceAccountRouterWrapper from "@bloonde/billing/invoice-accounts/wrappers/InvoiceAccountRouterWrapper";
import FillInvoiceAccountRouterWrapper from "@bloonde/billing/invoice-accounts/wrappers/FillInvoiceAccountRouterWrapper";
import ReviewRouterWrapper from "@bloonde/reviews/reviews/wrappers/ReviewRouterWrapper";
import ContactRouterWrapper from "@beeldit/crm/contacts/wrappers/ContactRouterWrapper";
import NotificationRouterWrapper from "@beeldit/crm/notifications/wrappers/NotificationRouterWrapper";
import CampaignRouterWrapper from "@beeldit/crm/campaign/wrappers/CampaignRouterWrapper";
import ReportRouterWrapper from "./reports/wrappers/ReportRouterWrapper";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <ConfigurationsProvider>
          <div className="App">
            <Outlet />
          </div>
        </ConfigurationsProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/",
        index: true,
        element: <Navigate to="/panel/1/1/events" />,
      },
      {
        path: "/panel",
        index: true,
        element: <Navigate to="/panel/1/1/events" />,
      },
      {
        path: "/panel/:tenantId/:commerceId",
        element: (
          <TenantAndCommerceProvider>
            <Panel />
          </TenantAndCommerceProvider>
        ),
        children: [
          {
            path: "events",
            element: (
              <GenerateEventSessionsProvider>
                <EventListActionProvider>
                  <EventRemoveActionProvider>
                    <EventCreateActionProvider>
                      <EventEditActionProvider>
                        <EventsPage />
                      </EventEditActionProvider>
                    </EventCreateActionProvider>
                  </EventRemoveActionProvider>
                </EventListActionProvider>
              </GenerateEventSessionsProvider>
            ),
          },
          {
            path: "events/:eventId",
            element: (
              <EventDetailsActionProvider>
                <EventDashboardPage />
              </EventDetailsActionProvider>
            ),
          },
          {
            path: "event-rule-price",
            element: (
              <EventRulePriceProvider>
                <EventRulePricePage />
              </EventRulePriceProvider>
            ),
          },
          {
            path: "header",
            element: (
              <HeaderDetailsActionProvider>
                <HeaderDashboardPage />
              </HeaderDetailsActionProvider>
            ),
          },
          {
            path: "footer",
            element: (
              <FooterDetailsActionProvider>
                <FooterDashboardPage />
              </FooterDetailsActionProvider>
            ),
          },
          {
            path: "qr-scanner",
            element: (
              <QRScannerDetailsActionProvider>
                <QRScannerDashboardPage />
              </QRScannerDetailsActionProvider>
            ),
          },
          {
            path: "users-activity",
            element: (
              <UsersActivityListActionProvider>
                <UsersActivityDetailsActionProvider>
                  <UsersActivityPage />
                </UsersActivityDetailsActionProvider>
              </UsersActivityListActionProvider>
            ),
          },
          {
            path: "pages",
            element: (
              <PageListActionProvider
                initialUrlFragment="1/1/pages"
                key="pages"
              >
                <PageRemoveActionProvider>
                  <PageCreateActionProvider>
                    <PagesPage
                      config={{ showCreation: false }}
                      filters={{ type: 1 }}
                    />
                  </PageCreateActionProvider>
                </PageRemoveActionProvider>
              </PageListActionProvider>
            ),
          },
          {
            path: "pages/:pageId",
            element: (
              <PageDetailsActionProvider>
                <PageEditionPage />
              </PageDetailsActionProvider>
            ),
          },
          {
            path: "articles",
            element: (
              <PageRouterWrapper
                config={{ showCreation: true, title: "articles" }}
                filters={{ type: 3 }}
                formDefaultValues={{ type: 3, schema_slug: "blog-article" }}
                initialUrlFragment="1/1/articles"
                key="articles"
              />
            ),
          },
          {
            path: "articles/:pageId",
            element: (
              <PageDetailsActionProvider>
                <PageEditionPage />
              </PageDetailsActionProvider>
            ),
          },
          {
            path: "users",
            element: (
              <UserListActionProvider>
                <UserRemoveActionProvider>
                  <UserCreateActionProvider>
                    <UserEditActionProvider>
                      <UserPage />
                    </UserEditActionProvider>
                  </UserCreateActionProvider>
                </UserRemoveActionProvider>
              </UserListActionProvider>
            ),
          },
          {
            path: "coupons",
            element: (
              <CouponListActionProvider>
                <CouponRemoveActionProvider>
                  <CouponCreateActionProvider>
                    <CouponEditActionProvider>
                      <CouponsPage />
                    </CouponEditActionProvider>
                  </CouponCreateActionProvider>
                </CouponRemoveActionProvider>
              </CouponListActionProvider>
            ),
          },
          {
            path: "assignable-types",
            element: (
              <AssignableTypeListActionProvider>
                <AssignableTypeRemoveActionProvider>
                  <AssignableTypeCreateActionProvider>
                    <AssignableTypeEditActionProvider>
                      <AssignableTypesPage />
                    </AssignableTypeEditActionProvider>
                  </AssignableTypeCreateActionProvider>
                </AssignableTypeRemoveActionProvider>
              </AssignableTypeListActionProvider>
            ),
          },
          {
            path: "features",
            element: (
              <FeatureListActionProvider>
                <FeatureRemoveActionProvider>
                  <FeatureCreateActionProvider>
                    <FeatureEditActionProvider>
                      <FeaturesPage />
                    </FeatureEditActionProvider>
                  </FeatureCreateActionProvider>
                </FeatureRemoveActionProvider>
              </FeatureListActionProvider>
            ),
          },
          {
            path: "candidates",
            element: (
              <CandidateListActionProvider>
                <CandidateRemoveActionProvider>
                  <CandidateCreateActionProvider>
                    <CandidateEditActionProvider>
                      <CandidatesPage />
                    </CandidateEditActionProvider>
                  </CandidateCreateActionProvider>
                </CandidateRemoveActionProvider>
              </CandidateListActionProvider>
            ),
          },
          {
            path: "assignables",
            element: (
              <AssignableListActionProvider>
                <AssignableRemoveActionProvider>
                  <AssignableCreateActionProvider>
                    <AssignableEditActionProvider>
                      <AssignablesPage />
                    </AssignableEditActionProvider>
                  </AssignableCreateActionProvider>
                </AssignableRemoveActionProvider>
              </AssignableListActionProvider>
            ),
          },
          {
            path: "assignments",
            element: (
              <AssignmentDetailsActionProvider>
                <AssignmentGenerateAssignmentsProvider>
                  <AssignmentListActionProvider>
                    <AssignmentRemoveActionProvider>
                      <AssignmentCreateActionProvider>
                        <AssignmentEditActionProvider>
                          <AssignmentsPage />
                        </AssignmentEditActionProvider>
                      </AssignmentCreateActionProvider>
                    </AssignmentRemoveActionProvider>
                  </AssignmentListActionProvider>
                </AssignmentGenerateAssignmentsProvider>
              </AssignmentDetailsActionProvider>
            ),
          },
          {
            path: "assignments/:assignmentId",
            element: (
              <EditAssignationActionProvider>
                <AssignmentDetailsActionProvider>
                  <AssignmentPage />
                </AssignmentDetailsActionProvider>
              </EditAssignationActionProvider>
            ),
          },
          {
            path: "invoices",
            element: <InvoiceRouterWrapper />,
          },
          {
            path: "invoice-accounts",
            element: <InvoiceAccountRouterWrapper />,
          },
          {
            path: "reviews",
            element: (
              <ReviewRouterWrapper
                formDefaultValues={{
                  reviewable_id: 1,
                  reviewable_type: "App\\Models\\Commerce",
                }}
              ></ReviewRouterWrapper>
            ),
          },
          {
            path: "contacts",
            element: <ContactRouterWrapper />,
          },
          {
            path: "notifications",
            element: <NotificationRouterWrapper />,
          },
          {
            path: "campaigns",
            element: <CampaignRouterWrapper />,
          },
          {
            path: "reports",
            element: <ReportRouterWrapper />,
          },
        ],
      },
      {
        path: "/panel/:tenantId",
        element: (
          <TenantAndCommerceProvider>
            <Panel />
          </TenantAndCommerceProvider>
        ),
        children: [
          // {
          //   path: "commerces",
          //   element: (
          //     <CommerceListActionProvider>
          //       <CommerceRemoveActionProvider>
          //         <CommerceCreateActionProvider>
          //           <CommerceEditActionProvider>
          //             <CommercesPage />
          //           </CommerceEditActionProvider>
          //         </CommerceCreateActionProvider>
          //       </CommerceRemoveActionProvider>
          //     </CommerceListActionProvider>
          //   ),
          // },
          {
            path: "commerces/:commerceId",
            element: (
              <CommerceDetailsActionProvider>
                <CommercePage />
              </CommerceDetailsActionProvider>
            ),
          },
        ],
      },
      {
        path: "/panel",
        element: (
          <TenantAndCommerceProvider>
            <Panel />
          </TenantAndCommerceProvider>
        ),
        children: [
          // {
          //   path: "tenants",
          //   element: (
          //     <TenantListActionProvider>
          //       <TenantRemoveActionProvider>
          //         <TenantCreateActionProvider>
          //           <TenantEditActionProvider>
          //             <TenantsPage />
          //           </TenantEditActionProvider>
          //         </TenantCreateActionProvider>
          //       </TenantRemoveActionProvider>
          //     </TenantListActionProvider>
          //   ),
          // },
        ],
      },
      {
        path: "invoices",
        children: [
          {
            path: "fill-invoice-account/:token",
            element: <FillInvoiceAccountRouterWrapper />,
          },
        ],
      },
    ],
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
