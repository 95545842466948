import { ObjectFieldTemplateProps } from "@rjsf/utils";

function EventMain(props: ObjectFieldTemplateProps, func: any) {
  return (
    <>
      <div className="cms-section">
        <div className="h3">{props.title}</div>
        <div className="section row">
          <div className="col-4">
            <div className="property-wrapper">
              {func("city", props)?.content}
            </div>
          </div>
          <div className="col-4">
            <div className="property-wrapper">
              {func("duration", props)?.content}
            </div>
          </div>
          <div className="col-4">
            <div className="property-wrapper">
              {func("language", props)?.content}
            </div>
          </div>
        </div>
        <div className="section row">
          <div className="col-12">
            <div className="property-wrapper">
              {func("wysiwyg", props)?.content}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventMain;
