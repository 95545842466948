import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import ReviewSchemaSelector from "@bloonde/reviews/review-schemas/components/ReviewSchemaSelector";
import index from "@beeldit/core/services";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
  formWidgets: any;
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function ReviewForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const [reviewSchemas, setReviewSchemas] = useState<any>(null);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});
  const translations = {
    reviewer_id: t("reviewer_id"),
    rating: t("rating"),
    comment: t("comment"),
    review_schema_id: t("review_schema_id"),
    date: t("date"),
  };

  let schema: RJSFSchema = {
    type: "object",
    properties: {
      review_schema_id: {
        type: "number",
        title: translations.review_schema_id,
      },
      date: {
        type: "string",
        format: "datetime",
        title: "Date",
      },
      reviewer_id: {
        type: "number",
        title: translations.reviewer_id,
      },
      value: {
        type: "object",
        title: "",
        properties: {
          type: {},
        },
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    reviewer_id: {
      "ui:widget": "reviewer-selector",
    },
    review_schema_id: {
      "ui:widget": "review-schema-selector",
    }
  };
  const customWidgets = {
    ...widgets,
    ...{
      "reviewer-selector": props.formWidgets.reviewer_id,
      "review-schema-selector": ReviewSchemaSelector,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    if (!props.formWidgets.reviewer_id) {
      delete schema.properties.reviewer_id;
    }
    let newSchema = processSchemaFunction(schema, element);
    if (reviewSchemas) {
      let reviewSchema = reviewSchemas.find((reviewSchema: any) => {
        return reviewSchema.id === element.review_schema_id;
      });
      if (reviewSchema && reviewSchema.schema) {
        newSchema.properties = { ...newSchema.properties, ...{value: reviewSchema.schema} };
      }
    }

    setFinalSchema(newSchema);
  }, [element, reviewSchemas]);

  useEffect(() => { // TODO - Habrá que ver, porque por alguna razón hace multiples llamadas
    index("review_schemas").then((response: any) => {
      setReviewSchemas(response.data.elements);
    });
  }, []);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default ReviewForm;
