import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { t } from "i18next";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { User } from "@beeldit/user-and-access/users/models/User";
import { TenantAndCommerceContext } from "@main/contexts/TenantAndCommerceContext";

interface MenuItem {
  label: string;
  to: string;
  children?: MenuItem[];
  icon?: any;
  roles?: string[];
}

interface SidebarProps {
  items: MenuItem[];
}

function createMenuItem(item: MenuItem, user: User, key?: string) {
  if (item.children) {
    if (item.roles && !item.roles.includes(user.role)) return null;
    return (
      <div key={key}>
        <NavDropdown          
          title={item.label}
          key={key}
          id={""}
        >
          {item.children.map((item: MenuItem, key2: any) => {
            let auxKey = key + "-" + key2;
            return createMenuItem(item, user, auxKey);
          })}
        </NavDropdown>
      </div>
    );
  }

  if (item.roles && !item.roles.includes(user.role)) return null;
  return (
    <Nav.Item key={key}>
      <NavLink to={item.to} className="nav-link hover-translate text-black">
        {item.icon} {item.label}
      </NavLink>
    </Nav.Item>
  );
}

function Sidebar(props: SidebarProps) {
  const { user } = useContext(AuthContext);

  const { items } = props;

  const { setCommerceId } = useContext(TenantAndCommerceContext);

  return (
    <Nav className="flex-column sidebar d-none d-lg-block p-3">
      <Navbar.Brand key="brand" href="#home">
        <img src="/logo-vertical.png" alt="Hispalrutas" width="180" />
      </Navbar.Brand>
      {items.map((item: MenuItem, key: any) => {
        return createMenuItem(item, user, key);
      })}
      <Nav.Item key="logout" className="border-top bottom">
        <NavLink          
          onClick={() => {
            setCommerceId(null);
          }}
          to="/login"
          className="nav-link hover-translate text-black-50"
        >
          {t("logout")}
        </NavLink>
      </Nav.Item>
    </Nav>
  );
}

export default Sidebar;
