import { ObjectFieldTemplateProps } from "@rjsf/utils";

function ImgWysiwygLinkTemplate(props: ObjectFieldTemplateProps, func: any) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper">
            {func("elements", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {func("button_link", props)?.content}
          </div>
        </div>
      </div>
    </>
  );
}

export default ImgWysiwygLinkTemplate;
