import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { addHeader } from "@beeldit/core/helpers/api";

import { TenantAndCommerceContext } from "@main/contexts/TenantAndCommerceContext";
import { t } from "i18next";
import {
  CalendarBlank,
  Gear,
  ArrowSquareUp,
  ArrowSquareDown,
  QrCode,
  Notepad,
  HandPalm,
  MegaphoneSimple,
} from "@phosphor-icons/react";

import { getApi } from "@beeldit/core/helpers/api";
import useGetConfigurationAction from "@main/configurations/actions/get/useGetConfigurationAction";
import { ConfigurationsContext } from "@main/contexts/ConfigurationsContext";
import {
  Article,
  Browsers,
  ChartLine,
  ChatDots,
  CirclesThree,
  CurrencyCircleDollar,
  Graph,
  GridNine,
  IdentificationCard,
  Percent,
  PuzzlePiece,
  Star,
  Unite,
  Users,
} from "@phosphor-icons/react/dist/ssr";
import useGetCommerceAction from "@main/commerces/actions/get-config/useGetCommerceAction";

function Panel() {
  const { tokenLoaded, user } = useContext(AuthContext);

  /** TODO - Encapsular en una logica aparte que además compruebe que estos parametros son numericos */
  const { setTenantId } = useContext(TenantAndCommerceContext);
  const { setCommerceId } = useContext(TenantAndCommerceContext);
  const { getCommerceFunction } = useGetCommerceAction();

  let { tenantId, commerceId } = useParams();

  useEffect(() => {
    if (!tenantId) {
      tenantId = "1";
    }
    setTenantId(parseInt(tenantId));
    getApi().defaults.headers.common["tenantId"] = tenantId;
    if (!commerceId) {
      commerceId = "1";
    }
    setCommerceId(parseInt(commerceId));
    getApi().defaults.headers.common["commerceId"] = commerceId;
  }, []);

  const { getConfigurationFunction } = useGetConfigurationAction();
  const { setLangs, setSchemas } = useContext(ConfigurationsContext);

  useEffect(() => {
    if (tokenLoaded) {
      getConfigurationFunction("langs", setLangs);
      getConfigurationFunction("schemas", setSchemas);
    }
  }, [tokenLoaded]);

  useEffect(() => {
    if (!commerceId || !tokenLoaded) return;
    getCommerceFunction(parseInt(commerceId));
  }, [commerceId, tokenLoaded]);

  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }

  const items: any[] = [
    {
      label: t("events"),
      to: `/panel/${tenantId}/${commerceId}/events`,
      icon: <CalendarBlank size={24} />,
      roles: ["admin"],
    },
    {
      label: t("pages"),
      to: `/panel/${tenantId}/${commerceId}/pages`,
      icon: <Browsers size={24} />,
      roles: ["admin"],
    },
    {
      label: t("articles"),
      to: `/panel/${tenantId}/${commerceId}/articles`,
      icon: <Article size={24} />,
      roles: ["admin"],
    },
    {
      label: t("qr_scanner"),
      to: `/panel/${tenantId}/${commerceId}/qr-scanner`,
      icon: <QrCode size={24} />,
      roles: ["validator"],
    },
    // {
    //   label: (
    //     <>
    //       <PuzzlePiece size={24} />
    //       {t("resources_managements")}
    //     </>
    //   ),
    //   roles: ["admin"],
    //   children: [
    //     {
    //       label: t("assignments"),
    //       to: `/panel/${tenantId}/${commerceId}/assignments`,
    //       icon: <Unite size={24} />,
    //     },
    //     {
    //       label: t("assignables"),
    //       to: `/panel/${tenantId}/${commerceId}/assignables`,
    //       icon: <GridNine size={24} />,
    //     },
    //     {
    //       label: t("candidates"),
    //       to: `/panel/${tenantId}/${commerceId}/candidates`,
    //       icon: <HandPalm size={24} />,
    //     },
    //     {
    //       label: t("features"),
    //       to: `/panel/${tenantId}/${commerceId}/features`,
    //       icon: <Star size={24} />,
    //     },
    //     {
    //       label: t("assignable_types"),
    //       to: `/panel/${tenantId}/${commerceId}/assignable-types`,
    //       icon: <CirclesThree size={24} />,
    //     },
    //   ],
    // },
    // {
    //   label: t("assignments"),
    //   to: `/panel/${tenantId}/${commerceId}/assignments`,
    //   icon: <Unite size={24} />,
    //   roles: ["guide"],
    // },
    {
      label: (
        <>
          <CurrencyCircleDollar size={24} />
          {t("billing")}
        </>
      ),
      roles: ["admin"],
      children: [
        {
          label: t("invoices"),
          to: `/panel/${tenantId}/${commerceId}/invoices`,
          icon: <CurrencyCircleDollar size={24} />,
        },
        {
          label: t("invoice_accounts"),
          to: `/panel/${tenantId}/${commerceId}/invoice-accounts`,
          icon: <IdentificationCard size={24} />,
        },
      ],
    },
    {
      label: t("invoices"),
      to: `/panel/${tenantId}/${commerceId}/invoices`,
      icon: <CurrencyCircleDollar size={24} />,
      roles: ["guide"],
    },
    {
      label: t("reviews"),
      to: `/panel/${tenantId}/${commerceId}/reviews`,
      icon: <Star size={24} />,
      roles: ["admin"],
    },
    {
      label: (
        <>
          <MegaphoneSimple size={24} />
          {t("communications")}
        </>
      ),
      roles: ["admin"],
      children: [
        {
          label: t("contacts"),
          to: `/panel/${tenantId}/${commerceId}/contacts`,
          icon: <Users size={24} />,
        },
        {
          label: t("notifications"),
          to: `/panel/${tenantId}/${commerceId}/notifications`,
          icon: <ChatDots size={24} />,
        },
        {
          label: t("campaigns"),
          to: `/panel/${tenantId}/${commerceId}/campaigns`,
          icon: <MegaphoneSimple size={24} />,
        },
      ],
    },
    {
      label: t("reports"),
      to: `/panel/${tenantId}/${commerceId}/reports`,
      icon: <ChartLine size={24} />,
      roles: ["admin"],
    },
    {
      label: (
        <>
          <Gear size={24} />
          {t("configurations")}
        </>
      ),
      roles: ["admin"],
      children: [
        {
          label: t("commerce"),
          to: `/panel/${tenantId}/commerces/${commerceId}`,
          icon: <Gear size={24} />,
        },
        {
          label: t("users"),
          to: `/panel/${tenantId}/${commerceId}/users`,
          icon: <Users size={24} />,
          roles: ["admin"],
        },
        {
          label: t("coupons"),
          to: `/panel/${tenantId}/${commerceId}/coupons`,
          icon: <Percent size={24} />,
        },
        {
          label: t("header"),
          to: `/panel/${tenantId}/${commerceId}/header`,
          icon: <ArrowSquareUp size={24} />,
        },
        {
          label: t("footer"),
          to: `/panel/${tenantId}/${commerceId}/footer`,
          icon: <ArrowSquareDown size={24} />,
        },
        {
          label: t("users_activity"),
          to: `/panel/${tenantId}/${commerceId}/users-activity`,
          icon: <Notepad size={24} />,
          roles: ["admin"],
        },
      ],
    },
  ];

  return (
    <div>
      <div className="admin container-fluid">
        <div className="row">
          <Sidebar items={items} />
          <div className="page-content">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
